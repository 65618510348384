import classNames from 'classnames';

import styles from './Checkbox.module.scss';
import { CheckboxPropsType } from './Checkbox.props';
import { ReactComponent as CheckboxIcon } from '../../assets/ui/ticksquare.svg';
export const Checkbox = ({ checked, onClick, disabled }: CheckboxPropsType) => {
  return (
    <CheckboxIcon
      className={classNames(styles.chekbox, checked && styles.checked)}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    />
  );
};
