import { useCallback, useEffect, useRef, useState } from 'react';

import { FormType } from '../../types/FormType/FormType';
import { getDateError, getCharacterCount } from '../../helpers/formValidations';

export const useForm = (initinalForm: FormType) => {
  const [form, setForm] = useState<FormType>(initinalForm);
  const [isValidForm, setIsValidForm] = useState(false);
  const [isChangedForm, setIsChangedForm] = useState(false);

  const prevForm = useRef(initinalForm);

  const formValues = Object.values(form);
  const formKeys = Object.keys(form);

  const getIsFieldValid = (fieldName: string, value?: string | number) => {
    const type = form[fieldName].type;

    if (type === 'input') {
      switch (fieldName) {
        case 'name':
          return getCharacterCount(String(value));
        case 'description':
          return getDateError(String(value));
        case 'year':
          return getCharacterCount(String(value));
        case 'city':
          return getCharacterCount(String(value));
        case 'height':
          return getCharacterCount(String(value));
        case 'bet':
          return getCharacterCount(String(value));
        case 'redemption':
          return getCharacterCount(String(value));
        default:
      }
    }

    if (type === 'textarea') {
      switch (fieldName) {
        case 'description':
          // потом поменять, поставил для теста такую функцию валидации
          return getDateError(String(value));
        default:
      }
    }

    if (type === 'calendar') {
      switch (fieldName) {
        case 'startDate':
          return getDateError(String(value));
        case 'endDate':
          return getDateError(String(value));
        default:
      }
    }
  };

  const getIsFormValid = () => {
    const requiredInputs = formKeys.filter((fieldName: string) => form[fieldName].isRequired);

    requiredInputs.forEach((fieldName: string) => {
      const currentValue = form[fieldName].value;
      setForm((prev) => ({
        ...prev,
        [fieldName]: {
          ...prev[fieldName],
          errorText: getIsFieldValid(fieldName, currentValue),
          isValid: !getIsFieldValid(fieldName, currentValue),
        },
      }));
    });
  };

  useEffect(() => {
    // проверка на то, что все обязательные поля прошли валидацию
    const isAllInputReady = formValues.filter(
      ({ isRequired, isValid, value }) => isRequired && isValid && value
    ).length;

    const countRequiredValue = formValues.filter(({ isRequired }) => isRequired === true).length;

    // проверка на изменение формы

    const isFormChanged = formKeys.every((key) => form[key].value === prevForm.current[key].value);
    console.log(isAllInputReady);

    // сетим значение true если форма изменилась и false, если данные остались не тронутыми

    if (isFormChanged) {
      setIsChangedForm(false);
    } else {
      setIsChangedForm(true);
    }

    // сетим стейт валидации полей в true или false в зависимости от проверки выше

    if (isAllInputReady === countRequiredValue) {
      setIsValidForm(true);
    } else {
      setIsValidForm(false);
    }
  }, [form, formKeys, formValues]);

  const handleResetFormInputs = () => {
    formKeys.map((fieldName: string) => {
      return setForm((prev) => ({
        ...prev,
        [fieldName]: {
          ...prev[fieldName],
          value: '',
        },
      }));
    });
  };

  const handleChangeForm = useCallback((fieldName: string, value?: string | number) => {
    setForm((prev) => ({
      ...prev,
      [fieldName]: {
        ...prev[fieldName],
        errorText: getIsFieldValid(fieldName, value),
        isValid: !getIsFieldValid(fieldName, value),
        value: value,
      },
    }));
  }, []);

  return {
    form,
    handleChangeForm,
    isValidForm,
    isChangedForm,
    getIsFormValid,
    formKeys,
    handleResetFormInputs,
  };
};
