// import { useAppSelector } from '@/app/store/hooks';

import classNames from 'classnames';
import { ChatRoomT } from 'entities/chats/types';

import styles from './ChatWidget.module.scss';
import { ChatBox } from './Components/ChatBox';
import { ChatHeader } from './Components/ChatHeader';
import { ChatList } from './Components/ChatList';
import { ChatSearch } from './Components/ChatSearch';
export const ChatWidget = () => {
  // const { chatRooms, activeChatId, chats } = useAppSelector((state) => state.chats);
  // const activeChat = chats.find((c) => c.chatRoomId === activeChatId);
  // const activeRoom = chatRooms.find((c) => c.id === activeChatId);
  // const status = activeRoom?.status || null;
  // const name = activeRoom?.name || null;
  const activeChatId = 1;
  const name = 'manager';
  const chatRooms: Array<ChatRoomT> = [];
  const status = null;
  const activeChat = undefined;
  return (
    <div className={styles.widget}>
      <div className={classNames(styles.left, Boolean(activeChatId) && [styles.hide])}>
        <ChatSearch />
      </div>
      <div className={classNames(styles.right, styles.top, Boolean(activeChatId) && [styles.show])}>
        <ChatHeader name={name} />
      </div>
      <div className={classNames(styles.left, { [styles.hide]: Boolean(activeChatId) })}>
        <ChatList chatRooms={chatRooms} />
      </div>
      <div
        className={classNames(styles.right, { [styles.show]: Boolean(activeChatId) }, [
          styles.bottom,
        ])}
      >
        <ChatBox chat={activeChat} status={status} name={name} />
      </div>
    </div>
  );
};
