import { useState } from 'react';
import { Modal } from 'shared/ui/Modal';
import { Button } from 'shared/ui/Button';

import styles from './YouDontSaveModal.module.scss';

export const YouDontSaveModal = () => {
  const [isVisible, setIsVisible] = useState(true);
  return (
    <Modal isVisible={isVisible} close={() => setIsVisible(false)}>
      <p className={styles.title}>Вы не сохранили изменения в анкете</p>
      <span className={styles.subtitle}>Все равно выйти?</span>
      <div className={styles.button_container}>
        <Button type={4}>Выйти</Button>
        <Button type={3}>Перейти</Button>
      </div>
    </Modal>
  );
};
