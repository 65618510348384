import { BonusCard } from 'entities/bonuses/ui/BonusCard';

import styles from './BonusesWidgets.module.scss';
export const BonusesWidget = () => {
  return (
    <div className={styles.block}>
      <BonusCard status="disabled" forUnlock={2500} />
      <BonusCard status="available" forUnlock={0} />
      <BonusCard status="activated" forUnlock={0} />
    </div>
  );
};
