import axios from 'axios';
import { NotificationBotI } from 'pages/AdminPage/interfaces';
import { Dispatch, SetStateAction } from 'react';

export const BASE_URL = process.env.REACT_APP_PRODUCTION_URL;

export const instance = axios.create({ baseURL: BASE_URL });

instance.interceptors.request.use((config) => {
  if (config.headers) {
    config.headers.Authorization = 'Token awdkjrnfernferifnriefnicmoi';
  }

  return config;
});

export const catalog = {
  getCatalogs() {
    return axios.get(`${BASE_URL}/catalogs`);
  },
  getChatId(token: string) {
    return axios.get(`${BASE_URL}/catalogs-chat-ids`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  },

  changeCatalogsPrice(id: number, price: number, token: string) {
    return axios.put(
      `${BASE_URL}/catalogs/${id}`,
      { price },
      {
        headers: {
          Authorization: `Token ${token} `,
        },
      }
    );
  },

  deleteChatId(id: number, token: string) {
    return axios.delete(`${BASE_URL}/catalogs-chat-ids/${id}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  },

  updateChatId(telegramChatId: string, token: string, id: number) {
    return axios.put(
      `${BASE_URL}/catalogs-chat-ids/${id}`,
      { telegramChatId },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
  },

  createChatId(token: string, catalogId: number) {
    return axios.post(
      `${BASE_URL}/catalogs-chat-ids`,
      { catalogId, telegramChatId: '' },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
  },

  changePreOrder(token: string, preOrder: boolean, id: number) {
    return axios.put(
      `${BASE_URL}/catalogs/${id}`,
      { preOrder },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
  },
};

export const fecthChatIds = async (
  setChatIds: any,
  token: string,
  setSuccess?: Dispatch<SetStateAction<boolean>> | undefined
) => {
  try {
    const { data } = await catalog.getChatId(token);
    const allChatIds = data.chats;
    setSuccess?.(true);
    setChatIds(allChatIds);
  } catch (error) {
    console.log(error);
  }
};

export const telegramBotImages = {
  getTelegramBotImages(token: string) {
    return axios.get(`${BASE_URL}/telegram-bot-images`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  },
  changeCurrentTelegramBotImage(id: number | undefined, image: string, token: string) {
    return axios.put(
      `${BASE_URL}/telegram-bot-images/${id}`,
      {
        image,
      },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
  },
};

export const notificationsBotApi = {
  createBotNotification(notification: any, token: string) {
    return axios.post(
      `${BASE_URL}/actionNotifications/create`,
      {
        ...notification,
      },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
  },
  getAllNotificationBot(token: string) {
    return axios.get(`${BASE_URL}/actionNotifications/get`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  },
  editNotificationBot(notification: any, token: string) {
    const { text, period, name, stipulation, id } = notification;
    return axios.patch(
      `${BASE_URL}/actionNotifications/edit`,
      {
        text,
        period,
        name,
        stipulation,
        id,
      },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
  },
  deleteNotification(id: number, token: string) {
    return axios.delete(`${BASE_URL}/actionNotifications/delete?id=${id}`, {
      headers: {
        Authorization: `Token ${token}`,
      },
    });
  },
  createBotTargetNotification(notification: any, token: string) {
    return axios.post(
      `${BASE_URL}/dispatch`,
      {
        ...notification,
      },
      {
        headers: {
          Authorization: `Token ${token}`,
        },
      }
    );
  },
};
