import { Button } from 'shared/ui/Button';

import { ReactComponent as TrashIcon } from '../../../shared/assets/icons/trash.svg';

export const DeletePhotoButton = () => {
  return (
    <Button type={4}>
      <TrashIcon />
    </Button>
  );
};
