import { useAppDispatch } from 'app/store';
import { changeAuction, createAuction } from 'entities/auctions/api';
import { FileType } from 'shared/types/FileType';
import { FormType } from 'shared/types/FormType/FormType';
import { useState } from 'react';
import { ToastVariants } from 'shared/context/ToastContext/interfaces';
import { AuctionPhoto } from 'entities/auctions/types';

import { useForm } from '../useForm/useForm';
import { useToast } from '../useToast';

export const useAuction = (
  name?: string,
  startDate?: string,
  endDate?: string,
  description?: string,
  initialBet?: number,
  images?: AuctionPhoto[],
  fullRedemption?: string
) => {
  const initialForm: FormType = {
    name: {
      type: 'input',
      id: 2,
      isValid: false,
      placeholder: 'Название',
      isRequired: true,
      value: name || '',
      errorText: '',
    },
    startDate: {
      type: 'calendar',
      id: 0,
      isValid: false,
      placeholder: 'Дата начала',
      isRequired: true,
      value: startDate || '',
    },
    endDate: {
      type: 'calendar',
      value: endDate || '',
      id: 10,
      isValid: false,
      placeholder: 'Дата окончания',
      isRequired: true,
    },
    bet: {
      type: 'input',
      id: 3,
      isValid: false,
      placeholder: 'Начальная ставка ($)',
      isRequired: true,
      value: initialBet || '',
      errorText: '',
    },
    redemption: {
      type: 'input',
      id: 3,
      isValid: false,
      placeholder: 'Сумма полного выкупа ($)',
      isRequired: true,
      value: fullRedemption || '',
      errorText: '',
    },
    publish: {
      type: 'calendar',
      id: 0,
      isValid: false,
      placeholder: 'Запланировать публикацию',
      isRequired: false,
      value: '',
    },
    description: {
      type: 'textarea',
      id: 4,
      placeholder: 'Описание аукциона',
      isRequired: true,
      isValid: false,
      errorText: '',
      value: description || '',
    },
  };

  const mappedImagesWithChangedAuction = images?.map(({ file, id }) => {
    return {
      id: id,
      full: file,
      preview: '',
      type: 'image',
    };
  }) as FileType[];

  const [isVisibleUploadModal, setIsVisibleUploadModal] = useState(false);
  const [photos, setPhotos] = useState<FileType[]>(mappedImagesWithChangedAuction || []);

  const {
    form,
    handleChangeForm,
    isValidForm,
    getIsFormValid,
    handleResetFormInputs,
    isChangedForm,
  } = useForm(initialForm);
  const { showToast } = useToast();

  const dispatch = useAppDispatch();

  const files = photos.map(({ full }) => full);

  const handleResetFullForm = () => {
    handleResetFormInputs();
    setPhotos([]);
  };

  console.log(files);

  const requestDate = {
    name: form['name'].value,
    startDate: form['startDate'].value,
    endDate: form['endDate'].value,
    initialBet: form['bet'].value,
    description: form['description'].value,
    fullRedemption: form['redemption'].value,
    photos: files,
  };

  const handleSetFiles = (files: FileType[]) => {
    setPhotos((prev) => [...prev, ...files]);
  };

  const handleChangeAuction = () => {
    getIsFormValid();
    if (isValidForm) {
      dispatch(changeAuction(requestDate));
      showToast({
        message: `Каталог успешно отредактирован, чтобы посмотреть перейдите на страницу Аукционы`,
        variant: ToastVariants.SUCCESS,
      });
    }
  };

  const handleCreateAuction = () => {
    getIsFormValid();
    if (isValidForm) {
      dispatch(createAuction(requestDate));
      handleResetFullForm();
      showToast({
        message: `Каталог успешно создан, чтобы посмотреть перейдите на страницу Аукционы`,
        variant: ToastVariants.SUCCESS,
      });
    }
  };

  const handleClickIsVisibleUploadModal = () => {
    setIsVisibleUploadModal(true);
  };

  return {
    handleChangeAuction,
    handleClickIsVisibleUploadModal,
    handleSetFiles,
    handleChangeForm,
    isVisibleUploadModal,
    isValidForm,
    setIsVisibleUploadModal,
    photos,
    form,
    setPhotos,
    handleCreateAuction,
    handleResetFullForm,
    isChangedForm,
  };
};
