import { Button } from 'shared/ui/Button';
import { ReactComponent as SearchSVG } from 'shared/assets/icons/search.svg';
import { ReactComponent as MoreSVG } from 'shared/assets/icons/more.svg';

import styles from './ChatHeader.module.scss';
export const ChatHeader = ({ name }: { name: string | null }) => {
  return (
    <div className={styles.box}>
      <span className={styles.name}>{name && name === 'manager' ? 'Менеджер' : name}</span>
      <div className={styles['btn-container']}>
        <Button type={1}>
          <SearchSVG />
        </Button>

        <Button type={1}>
          <MoreSVG />
        </Button>
      </div>
    </div>
  );
};
