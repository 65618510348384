import { BonusesWidget } from 'widgets/Bonuses/BonusesWidget';

import { Tabs } from '../../shared/ui/Tabs';
import { UserBanner } from '../../widgets/Users/UserBanner';
import { AuctionsTab } from './Tabs/AuctionsTab';
import { OrderTab } from './Tabs/OrdersTab';
import { ProfileTab } from './Tabs/ProfileTab';
import { SubscriptionsTab } from './Tabs/SubscriptionsTab';

const CurrentUser = () => {
  const tabs = [
    {
      content: <ProfileTab />,
      label: 'Профиль',
      id: 0,
    },
    {
      content: <OrderTab />,
      label: 'Заказы',
      id: 1,
    },
    {
      content: <AuctionsTab />,
      label: 'Аукционы',
      id: 2,
    },
    {
      content: <SubscriptionsTab />,
      label: 'Подписки',
      id: 3,
    },
    {
      content: <BonusesWidget />,
      label: 'Бонусы',
      id: 4,
    },
  ];
  return (
    <div style={{ width: '100%' }}>
      <UserBanner />
      <Tabs tabs={tabs} />
    </div>
  );
};

export default CurrentUser;
