import { Badge } from 'shared/ui/Badge';
import { Button } from 'shared/ui/Button';
import { ReactComponent as CheckedSVG } from 'shared/assets/icons/ticksquare.svg';
import { ReactComponent as BlockedSVG } from 'shared/assets/icons/bagcross.svg';

import styles from './BonusCard.module.scss';
type BonusCardT = {
  status: 'activated' | 'available' | 'disabled';
  forUnlock: number;
};
export const BonusCard = ({ status, forUnlock }: BonusCardT) => {
  return (
    <div className={`${styles.card} ${styles[status]}`}>
      <Badge value={`Бонус на 5000€`} additionalClasses={styles.badge} />
      <div className={styles.info}>
        <p className={styles.title}>200€ на депозит</p>
        <p className={styles.desc}>Всегда приятно</p>
      </div>
      <div className={styles.control}>
        <div className={styles.amount}>
          <span className={styles.label}>До разблокировки бонуса</span>
          <span className={styles.sum}>{forUnlock}€</span>
        </div>
        <Button
          type={status === 'available' ? 3 : 4}
          additionalClasses={styles.btn}
          disabled={status !== 'available'}
        >
          <>
            {status === 'activated' ? 'Получен' : 'Получить'}{' '}
            {status === 'activated' ? (
              <CheckedSVG className={styles.svg} />
            ) : status === 'disabled' ? (
              <BlockedSVG className={styles.svg} />
            ) : (
              ''
            )}
          </>
        </Button>
      </div>
    </div>
  );
};
