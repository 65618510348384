import { Link } from 'react-router-dom';

import { getFormatedDate } from '../../../../shared/helpers/getFormatedDate/getFormatedDate';
import { UserRowPropsI } from './UserRow.props';
import styles from './UserRow.module.scss';

export const UserRow = ({
  registerDate,
  sum,
  status,
  nickname,
  orders,
  auctions,
}: UserRowPropsI) => {
  const BoxDetails = [
    {
      placeholder: 'Сумма',
      text: sum.toLocaleString('ru-RU'),
      id: 1,
    },
    {
      placeholder: 'Статус',
      text: status,
      id: 2,
    },
    {
      placeholder: 'Заказы',
      text: orders,
      id: 3,
    },
    {
      placeholder: 'Аукционы',
      text: auctions,
      id: 4,
    },
  ];

  return (
    <li className={styles.item}>
      <p className={styles.placeholder_text}>{getFormatedDate(registerDate)}</p>
      <p className={styles.nickname}>{nickname}</p>
      {BoxDetails.map(({ placeholder, text, id }) => (
        <div key={id} className={styles.box}>
          <span className={styles.placeholder_text}>{placeholder}</span>
          <span className={styles.text}>{text}</span>
        </div>
      ))}
      <Link className={styles.more} to={'/users/1'}>
        Подробнее
      </Link>
    </li>
  );
};
