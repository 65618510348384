import { Dispatch, SetStateAction } from 'react';
import { Button } from 'shared/ui/Button';
import classNames from 'classnames';

import styles from './MobileChatBtns.module.scss';

type MobileChatBtnsPropsT = {
  filtered: boolean;
  setFiltered: Dispatch<SetStateAction<boolean>>;
};
export const MobileChatBtns = ({ filtered, setFiltered }: MobileChatBtnsPropsT) => {
  return (
    <div className={styles.block}>
      <Button
        type={1}
        additionalClasses={classNames(styles.btn, !filtered && styles.active)}
        onClick={() => {
          if (filtered) {
            setFiltered(false);
          }
        }}
      >
        {'Все чаты'}
      </Button>
      <Button
        type={1}
        additionalClasses={classNames(styles.btn, filtered && styles.active)}
        onClick={() => {
          if (!filtered) {
            setFiltered(true);
          }
        }}
      >
        {'Избранные'}
      </Button>
      <Button type={1} additionalClasses={styles.btn}>
        {'Удалить все чаты'}
      </Button>
    </div>
  );
};
