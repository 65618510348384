import { Tabs } from 'shared/ui/Tabs';
import { YouDontSaveModal } from 'widgets/Questionnaires/YouDontSaveModal';
import { useForm } from 'shared/hooks/useForm/useForm';
import { FormType } from 'shared/types/FormType/FormType';
import { Banner } from 'widgets/Questionnaires/Banner';

import styles from './CurrentQuestionnaire.module.scss';
import { ImageContainer } from '../../widgets/Questionnaires/ImageContainer';
import { ChangeForm } from '../../widgets/Questionnaires/ChangeForm';
import { Photos } from '../../widgets/Questionnaires/Photos';
import { Video } from '../../widgets/Questionnaires/Video';
import { Hot } from '../../widgets/Questionnaires/Hot';
import girlImg from '../../shared/assets/mock/mock_girl.png';

const CurrentQuestionnaire = () => {
  const tabs = [
    {
      content: <Photos />,
      label: 'Фото',
      id: 0,
    },
    {
      content: <Video />,
      label: 'Видео',
      id: 1,
    },
    {
      content: <Hot />,
      label: 'HOT 18+',
      isHot: true,
      id: 2,
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <Banner title="Редактировать анкету" buttonText="Сохранить изменения" />
      <div className={styles.content}>
        <ImageContainer image={girlImg} />
        <div className={styles.info_container}>
          <ChangeForm />
          <Tabs className={[styles.tabs]} tabs={tabs} />
        </div>
      </div>
      <YouDontSaveModal />
    </div>
  );
};

export default CurrentQuestionnaire;
