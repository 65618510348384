import { Dispatch, SetStateAction } from 'react';
import classNames from 'classnames';
import { ReactComponent as StarSVG } from 'shared/assets/ui/chatItem/star1.svg';

import styles from './FavouriteChatsBtn.module.scss';

type FavouriteChatsBtnT = {
  filtered: boolean;
  setFiltered: Dispatch<SetStateAction<boolean>>;
};
export const FavouriteChatsBtn = ({ filtered, setFiltered }: FavouriteChatsBtnT) => {
  return (
    <div
      className={classNames(styles.btn, filtered && styles.active)}
      onClick={() => setFiltered((prev: boolean) => !prev)}
    >
      <div className={styles['svg-block']}>
        <StarSVG />
      </div>
      <div className={styles['title-block']}>
        <p className={styles.title}>{'Избранные'}</p>
        <p className={styles.desc}>{'Избранные чаты'}</p>
      </div>
    </div>
  );
};
