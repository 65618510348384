import classNames from 'classnames';
import { MembersModal } from 'widgets/Auctions/MembersModal';
import { useState } from 'react';
import { useAppDispatch } from 'app/store';
import { closeAuction, deleteAuction, getAllAuctions } from 'entities/auctions/api';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'shared/constans/routes';

import { Button } from '../../../../../../shared/ui/Button';
import styles from './BussinessButtons.module.scss';
import { BussinessButtonsPropsI } from './BussinessButtons.props';

export const BussinessButtons = ({
  className,
  IsClosedTab = false,
  id,
  initialBet,
  description,
  endDate,
  startDate,
  name,
  fullRedemption,
  images,
}: BussinessButtonsPropsI) => {
  const [isVisibleMemberModal, setIsVisibleMembersModal] = useState(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCloseOrDeletedAuction = async () => {
    if (IsClosedTab) {
      await dispatch(deleteAuction(id));
      await dispatch(getAllAuctions(true));
    } else {
      await dispatch(closeAuction(id));
      await dispatch(getAllAuctions(false));
    }
  };

  const handleClickEditOrRepitAuction = () => {
    if (!IsClosedTab) {
      navigate(`${ROUTES.CHANGE_AUCTION}/${id}`, {
        replace: true,
        state: { id, description, initialBet, name, startDate, endDate, images, fullRedemption },
      });
      console.log(`${ROUTES.CHANGE_AUCTION}/${id}`);
    } else {
      console.log('test');
    }
  };

  return (
    <div className={classNames(styles.container, className && className)}>
      <Button onClick={() => setIsVisibleMembersModal(true)} type={3}>
        Участники и ставки
      </Button>
      <Button onClick={handleClickEditOrRepitAuction} type={4}>
        {IsClosedTab ? 'Повторить аукцион' : 'Редактировать'}
      </Button>
      <Button onClick={handleCloseOrDeletedAuction} type={4}>
        {IsClosedTab ? 'Удалить' : 'Закрыть аукцион'}
      </Button>
      <MembersModal
        isVisible={isVisibleMemberModal}
        close={() => setIsVisibleMembersModal(false)}
      />
    </div>
  );
};
