// import { useAppDispatch, useAppSelector } from '@/app/store';
// import { setActiveChat } from '@/entities/chats/model';
import { useEffect, useState } from 'react';
import { RemoveAllChatsBtn } from 'features/chat/RemoveAllChatsBtn';
import { ReactComponent as HeadPhoneSVG } from 'shared/assets//icons/headphone.svg';

import { ChatItem } from './Components/ChatItem';
import styles from './ChatList.module.scss';
import { FavouriteChatsBtn } from './Components/FavouriteChatsBtn';
import { ChatItemT } from './Components/ChatItem/ChatItem';
import { MobileChatBtns } from './Components/MobileChatBtns';

export const ChatList = ({ chatRooms }: { chatRooms: Array<ChatItemT> }) => {
  // const dispatch = useAppDispatch();
  // const { activeChatId } = useAppSelector((state) => state.chats);
  const [filteredChats, setFilteredChats] = useState<Array<ChatItemT>>([]);
  const [showFavourites, setShowFavourites] = useState(false);

  // useEffect(() => {
  //   const handleEscPress = (event: KeyboardEvent) => {
  //     if (activeChatId && event.code === 'Escape') {
  //       dispatch(setActiveChat(null));
  //     }
  //   };
  //   document.addEventListener('keydown', handleEscPress);
  //   return () => document.removeEventListener('keydown', handleEscPress);
  // }, [activeChatId, dispatch]);
  useEffect(() => {
    if (showFavourites) {
      setFilteredChats(chatRooms.filter((c) => c.favourite));
    } else {
      setFilteredChats(chatRooms);
    }
  }, [showFavourites, chatRooms]);
  return (
    <div className={`${styles.box} scroll`}>
      <FavouriteChatsBtn filtered={showFavourites} setFiltered={setShowFavourites} />
      <MobileChatBtns filtered={showFavourites} setFiltered={setShowFavourites} />
      {filteredChats.map((c) => (
        <ChatItem
          id={c.id}
          key={c.id}
          name={
            c.id === 'manager' ? (
              <>
                <HeadPhoneSVG className="mr5" />
                <span>{'Менеджер'}</span>
              </>
            ) : (
              c.name
            )
          }
          lastMessageTime={c.lastMessageTime}
          lastMessage={c.lastMessage}
          active={c.active}
          photo={c.photo as unknown as string}
          unreadMessagesCount={c.unreadMessagesCount}
          favourite={c.favourite}
        />
      ))}
      <RemoveAllChatsBtn />
    </div>
  );
};
