import { ReactComponent as AttachSvg } from 'shared/assets/ui/chatItem/attachsquare.svg';
import { ReactComponent as FavouriteSvg } from 'shared/assets/ui/chatItem/star.svg';
import { ReactComponent as AttachSvgBig } from 'shared/assets/ui/chatItem/attachsquarebig.svg';
import { ReactComponent as BinSvg } from 'shared/assets/ui/chatItem/bin.svg';
import { ReactElement, useState } from 'react';
import { convertISODateToTime } from 'shared/helpers/dateFunctions';
import classNames from 'classnames';

import styles from './ChatItem.module.scss';
// import { setActiveChat, setFavouriteChat } from '@/entities/chats/model';
export type ChatItemT = {
  id: number | string;
  name: string | ReactElement;
  lastMessageTime?: string;
  lastMessage?: string;
  active: boolean;
  photo: string;
  unreadMessagesCount: number;
  favourite: boolean;
};

export const ChatItem = ({
  id,
  name,
  lastMessageTime,
  lastMessage,
  active,
  photo,
  unreadMessagesCount,
  favourite,
}: ChatItemT) => {
  // const dispatch = useAppDispatch();
  const [positionStart, setPositionStart] = useState<null | number>(null);
  const [positionX, setPositionX] = useState<number>(0);

  return (
    <div className={styles['item-container']}>
      <div
        className={classNames(styles['chat-item'], active && styles.active)}
        onClick={() => {
          if (!active) {
            // dispatch(setActiveChat(id));
          }
        }}
        style={{ left: positionX || 0 }}
        onTouchStart={(e) => {
          setPositionStart(e.touches[0].clientX);
          // setPositionStart(positionX);
        }}
        onTouchMove={
          // onToucHandler

          (e) => {
            if (positionStart) {
              let positionX = e.touches[0].clientX - positionStart;
              setPositionX(positionX);
              // setPositionX(e.touches[0].clientX);
            }
          }
        }
        onTouchEnd={() => {
          if (positionX && positionX < -72) {
            setPositionX(-144);
          } else {
            setPositionX(0);
          }
        }}
      >
        <div className={styles.photo}>
          <img height={42} width={42} src={photo} alt="girl" />
        </div>
        <div className={styles['name-block']}>
          <p className={styles.name}>{name}</p>
          <p className={styles.message}>{lastMessage || ''}</p>
        </div>
        <div className={styles['icons-block']}>
          <div className={styles.icons}>
            <AttachSvg
              className={styles.attach}
              onClick={(e: any) => {
                e.stopPropagation();
                console.log('attach');
              }}
            />
            <FavouriteSvg
              className={classNames(styles.favourite, favourite && [styles.fav])}
              onClick={(e: any) => {
                e.stopPropagation();
                // dispatch(setFavouriteChat(id));
              }}
            />
          </div>
          <div className={styles.info}>
            <p className={styles.time} suppressHydrationWarning>
              {lastMessageTime && convertISODateToTime(lastMessageTime)}
            </p>
            {unreadMessagesCount > 0 && (
              <div className={styles['unread-messages-count']}>{unreadMessagesCount}</div>
            )}
          </div>
        </div>
      </div>
      <div
        className={classNames(styles['mob-btns-container'], { [styles['hide']]: positionX > 0 })}
      >
        <div className={styles.pin}>
          <AttachSvgBig />
          {'Закрепить'}
        </div>
        <div className={styles.delete}>
          <BinSvg />
          {'Удалить'}
        </div>
      </div>
    </div>
  );
};
