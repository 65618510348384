import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { changeAuction, closeAuction, createAuction, deleteAuction, getAllAuctions } from '../api';
import { AuctionsInitialStateI, responseAuctionsDataI } from './interfaces';
import { Auction } from '../types';

const initialState: AuctionsInitialStateI = {
  auctions: [],
};
const auctionsSlice = createSlice({
  name: 'questionnaires',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get all auctions
    builder.addCase(getAllAuctions.pending, (state) => {
      state.auctions = [];
    });
    builder.addCase(
      getAllAuctions.fulfilled,
      (state, action: PayloadAction<responseAuctionsDataI>) => {
        console.log(action.payload);
        state.auctions = action.payload.auctions;
      }
    );
    builder.addCase(getAllAuctions.rejected, (state) => {
      state.auctions = [];
    });
    // create auction
    builder.addCase(createAuction.pending, (state) => {});
    builder.addCase(createAuction.fulfilled, (state, action) => {});
    builder.addCase(createAuction.rejected, (state) => {});
    // close auction
    builder.addCase(closeAuction.pending, (state) => {});
    builder.addCase(closeAuction.fulfilled, (state, action) => {});
    builder.addCase(closeAuction.rejected, (state) => {});
    // delete auction
    builder.addCase(deleteAuction.pending, (state) => {});
    builder.addCase(deleteAuction.fulfilled, (state, action) => {});
    builder.addCase(deleteAuction.rejected, (state) => {});
    // change auction
    builder.addCase(changeAuction.pending, (state) => {});
    builder.addCase(changeAuction.fulfilled, (state, action) => {});
    builder.addCase(changeAuction.rejected, (state) => {});
  },
});
export const {} = auctionsSlice.actions;
export default auctionsSlice.reducer;
