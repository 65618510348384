import { ReactComponent as TrashSVG } from 'shared/assets/icons/trash3.svg';

import styles from './RemoveAllChatsBtn.module.scss';
export const RemoveAllChatsBtn = () => {
  return (
    <div className={styles.btn}>
      <div className={styles['svg-container']}>
        <TrashSVG />
      </div>
      <span>{'Удалить все чаты'}</span>
    </div>
  );
};
